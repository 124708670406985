import { render, staticRenderFns } from "./notPart.vue?vue&type=template&id=3bcd4738&scoped=true&"
import script from "./notPart.vue?vue&type=script&lang=js&"
export * from "./notPart.vue?vue&type=script&lang=js&"
import style0 from "./notPart.vue?vue&type=style&index=0&id=3bcd4738&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bcd4738",
  null
  
)

export default component.exports