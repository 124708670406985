import request from "@/utils/request";
import qs from "qs";
// 判断是否能够进入到我的页面
export function fissionOwnHomePage(data) {
  return request({
    url: "/mkt/fission/own_home_page",
    method: "post",
    data, //如果是application/x-www-form-urlencoded这种提交方式，可直接用qs.stringify
  });
}
// 获取客户详情
export function fissionMembDetail(data) {
  return request({
    url: "/mkt/fission/memb_detail",
    method: "post",
    data, //如果是application/x-www-form-urlencoded这种提交方式，可直接用qs.stringify
  });
}
// 获取当前用户的助力状态
export function fissionHelpState(data) {
  return request({
    url: "/mkt/fission/help_state",
    method: "post",
    data,
  });
}
// 接力接口
export function fissionHelpCode(data) {
  return request({
    url: "/mkt/fission/help",
    method: "post",
    data,
  });
}

// 获取活动详情
export function fissionActivityDetail(data) {
  return request({
    url: "/mkt/fission/activity_detail",
    method: "post",
    data,
  });
}
// 获取领奖页面的属性
export function fissionPrizeLinkAttrs(data) {
  return request({
    url: "/mkt/fission/prize_link_attrs",
    method: "post",
    data,
  });
}
// 获取已邀请人数
export function fissionInviteCount(data) {
  return request({
    url: "/mkt/fission/invite_count",
    method: "post",
    data,
  });
}

// 获取领奖页面提交
export function fissionPrizeApplys(data) {
  return request({
    url: "/mkt/fission/prize_applys",
    method: "post",
    data,
  });
}
// 获取邀请列表
export function fissionInviteList(data) {
  return request({
    url: "/mkt/fission/invite_list",
    method: "post",
    data,
  });
}
// 获取领奖列表
export function fissionAwardsList(data) {
  return request({
    url: "/mkt/fission/awards_list",
    method: "post",
    data,
  });
}
// 获取领奖人数
export function fissionAwardsCount(data) {
  return request({
    url: "/mkt/fission/awards_count",
    method: "post",
    data,
  });
}

// 获取微信签名
export function jssdkSignature(data) {
  return request({
    url: "/weix/jssdk_signature",
    method: "post",
    data,
  });
}
// 获取分享链接
export function shareLink(data) {
  return request({
    url: `/mkt/fission/invite`,
    method: "post",
    data,
  });
}
// 获取当前客户信息
export function mineInfo(data) {
  return request({
    url: `/mkt/fission/mine_info`,
    method: "post",
    data,
  });
}
