import axios from 'axios';
import router from '@/router';
import qs from 'qs';
import { Toast } from 'vant';

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 20000,
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// response 拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    console.log('====返回数据：' + JSON.stringify(res));
    if (response.headers.token) {
      window.sessionStorage.setItem('token', response.headers.token);
    }
    if (res.errcode !== 0) {
      if (res.errcode == 101) {
        if (window.location.pathname != '/login_before') {
          let href = window.location.href;
          sessionStorage.setItem('redirectPage', href);
        }
        router.push('/login_before');
      } else {
        router.push({ path: '/error', query: res });
      }
      return Promise.reject(res);
    }
    return response.data;
  },
  (error) => {
    router.push({ path: '/error' });
    // Message({
    //   message: "系统错误",
    //   type: "error",
    //   duration: 3000,
    // });

    return Promise.reject(error);
  }
);

function request({ url, data, dataType }) {
  const token = sessionStorage.getItem('token') || '';
  return service({
    url,
    data,
    method: 'post',
    headers: {
      'device-type': 'PC',
      'Content-Type': dataType || 'application/x-www-form-urlencoded',
      token,
    },
    transformRequest: [
      (data) => {
        console.log('地址：' + url + '====参数：' + JSON.stringify(data));
        if (dataType) {
          data = JSON.stringify(data);
        } else {
          data = qs.stringify(data, { arrayFormat: 'repeat' });
        }
        return data;
      },
    ],
  });
}

export default request;
