<template>
  <div class="get-prize">
    <div class="prize-num">
      <div class="prize-title active">奖品一</div>
      <div class="prize-title">奖品2</div>
      <div class="prize-title">奖品3</div>
      <div class="prize-title">奖品3</div>
      <div class="prize-title">奖品3</div>
      <div class="prize-title">奖品3</div>
    </div>
    <div class="prize-con">
      <div class="con-01">
        <div class="prize-img"></div>
        <div class="prize-title">
          <div class="name">奖品：红色年轮3D工艺心经 平安扣项链足金999一个</div>
          <div class="repertory">剩余999</div>
        </div>
      </div>
    </div>
    <div class="prize-pro">
      <div class="pro-title">
        <div><img src="../../assets/images/bao001.png" alt="" srcset="" /></div>
        <div>已成功邀请0人</div>
        <div><img src="../../assets/images/bao001.png" alt="" srcset="" /></div>
      </div>
      <div class="pro-box">
        <div class="pro-box-dot">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      </div>
      <div class="pro-bot">
        <div>邀请好友</div>
        <div>6人</div>
      </div>
    </div>
    <div class="but-box">
      <van-button
        class="but-con"
        color="linear-gradient(181deg, #FD462A, #FB8F84)"
        >还差6人，立即邀请</van-button
      >
    </div>
    <div class="suss-box">已有999人成功领取</div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.get-prize {
  background: #ffffff;
  border-radius: 0.2rem;
  .prize-num {
    height: 1.14rem;
    border-bottom: 0.02rem solid #f4f4f4;
    flex-grow: 1;
    padding-left: 0.28rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // 设置超过不换行，隐藏
    overflow-x: auto;
    white-space: nowrap;

    .prize-title {
      display: inline;
      padding: 0.08rem 0.5rem;
      font-size: 0.32rem;
      font-family: PingFang SC;
      background: #e3e3e3;
      color: #999999;
      border-radius: 0.4rem;
      line-height: 0.6rem;
      text-align: center;
      margin-right: 0.08rem;
    }

    .active {
      font-weight: bold;
      color: #ffffff;
      background: linear-gradient(180deg, #ff934c, #fc686f);
    }
  }
  // 隐藏进度条
  ::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
  .prize-con {
    padding: 0.4rem;
    .con-01 {
      display: flex;
      justify-content: flex-start;
      .prize-img {
        width: 1.48rem;
        height: 1.48rem;
        background: #333333;
      }
      .prize-title {
        width: 4rem;
        margin-left: 0.24rem;
        .name {
          font-size: 0.32rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
        }
        .repertory {
          border: 0.02rem solid #bfbfbf;
          border-radius: 0.06rem;
          font-size: 0.24rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #bfbfbf;
          line-height: 0.4rem;
          text-align: center;
          vertical-align: middle;
          margin-top: 0.16rem;
        }
      }
    }
  }
  .prize-pro {
    padding: 0.4rem;
    .pro-title {
      display: flex;
      justify-content: space-between;
      line-height: 0.66rem;
      padding: 0 0.2rem;
      margin-bottom: 0.28rem;
      img {
        width: 0.66rem;
        height: 0.66rem;
      }
    }
    .pro-box {
      width: 100%;
      height: 0.14rem;
      background: #cccccc;
      border-radius: 0.08rem;
      .pro-box-dot {
        display: flex;
        justify-content: space-between;
        position: relative;
        padding: 0 0.2rem;
        top: -0.22rem;
        .dot {
          width: 0.16rem;
          height: 0.16rem;
          background: #ffffff;
          border: 0.2rem solid #999999;
          box-shadow: 0 0.04rem 0.08rem 0 rgba(137, 137, 137, 0.26);
          border-radius: 50%;
        }
      }
    }
    .pro-bot {
      display: flex;
      justify-content: space-between;
      padding: 0 0.2rem;
      margin-top: 0.4rem;
    }
  }
  .but-box {
    padding: 0 0.8rem;
    .but-con {
      width: 100%;
      box-shadow: 0 0.04rem 0.02rem 0rem rgba(255, 255, 255, 0.6);
      border-radius: 0.44rem;
      font-size: 0.34rem;
      font-family: Source Han Sans CN;
      font-weight: 800;
      color: #ffffff;
      line-height: 0.6rem;
    }
  }
  .suss-box {
    font-size: 0.24rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #f84b48;
    line-height: 0.44rem;
    text-align: center;
    padding: 0.26rem 0rem 0.4rem 0rem;
  }
}
</style>
