import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

import "amfe-flexible/index";
import { VueJsonp } from "vue-jsonp";
Vue.use(VueJsonp);
// import { Button} from "vant";
// Vue.use(Button);
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);
// 引入公共样式
import "./style/index.scss";
// 复制的包
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
