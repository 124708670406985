<template>
  <div class="error">
    <div class="img-box">
      <img src="../assets/error.png" alt="" srcset="" />
    </div>
    <div class="box">{{ mes.errmsg }}</div>
    <div class="hint color-777 size-28">页面出现了问题，请联系客服</div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      mes: {},
    };
  },
  mounted() {
    this.mes = this.$route.query;
  },
  methods: {},
};
</script>
<style scoped>
.error {
  height: 100vh;
}
.img-box {
  display: flex;
  justify-content: center;
  padding-top: 1.7rem;
}
.box {
  padding: 0.5rem 0;
  text-align: center;
  font-size: 0.32rem;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #fb5951;
  margin-bottom: 2rem;
}
.hint {
  width: 5.1rem;
  height: 1.14rem;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}
</style>
