<template>
  <div class="active-list">
    <div class="list-head">
      <div
        class="head-title"
        :class="{ active: active == 'getUser' }"
        @click="changeInfo('getUser')"
      >
        <img
          v-if="active == 'getUser'"
          style="width: 0.4rem; height: 0.4rem"
          src="../../assets/images/gift001.png"
        />
        <img
          v-else
          style="width: 0.4rem; height: 0.4rem"
          src="../../assets/images/gift002.png"
        />
        获取用户
      </div>
      <div
        class="head-title"
        :class="{ active: active == 'firendSup' }"
        @click="changeInfo('firendSup')"
      >
        <img
          v-if="active == 'firendSup'"
          style="width: 0.4rem; height: 0.4rem"
          src="../../assets/images/zan001.png"
        />
        <img
          v-else
          style="width: 0.4rem; height: 0.4rem"
          src="../../assets/images/zan002.png"
        />
        好友支持
      </div>
    </div>
    <div
      class="list-content"
      :class="{ conLeft: active == 'getUser', conRight: active == 'firendSup' }"
    >
      <ul v-if="active == 'getUser'">
        <li v-for="item in inviteList" :key="item.id">
          <div class="con-img"></div>
          <div class="size-32 color-333">
            <div>{{ item.name }}</div>
            <div style="color: #f9625d">{{ item.name }}</div>
          </div>
          <div class="prize-title">{{ item.title }}</div>
        </li>
        <li class="empty d-f-c" v-if="!inviteList || inviteList.length == 0">
          <van-empty description="暂无数据" />
        </li>
      </ul>
      <ul v-if="active == 'firendSup'">
        <li v-for="item in awardList" :key="item.id">
          <div class="con-img"></div>
          <div class="size-32 color-333">{{ item.name }}</div>
          <div class="size-30 color-666">{{ item.title }}</div>
        </li>
        <li class="empty d-f-c" v-if="!awardList || awardList.length == 0">
          <van-empty description="暂无数据" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { fissionInviteList, fissionAwardsList } from "@/api/market";
export default {
  data() {
    return {
      inviteList: [],
      loading: false,
      awardList: [],
      active: "getUser",
      params: {
        fid: "22061015473078352838",
      },
    };
  },
  mounted() {
    this.getInviteList();
  },
  methods: {
    changeInfo(type) {
      this.active = type;
      if (type == "getUser") this.getInviteList();
      if (type == "firendSup") this.getAwardList();
    },
    // 获取邀请列表
    async getInviteList() {
      let { datas } = await fissionInviteList(this.params);
      this.inviteList = datas;
    },
    // 获取领奖列表
    async getAwardList() {
      let { datas } = await fissionAwardsList(this.params);
      this.awardList = datas;
    },
  },
};
</script>
<style lang="scss" scoped>
.active-list {
  width: 100%;
  background: linear-gradient(0deg, #fffbe4, #f84b48);
  border-radius: 0.2rem;
  padding: 0.18rem;
  box-sizing: border-box;
  .list-head {
    display: flex;
    justify-content: flex-start;
    .head-title {
      width: 50%;
      height: 1.04rem;
      font-size: 0.32rem;
      font-family: PingFang SC;
      font-weight: 600;
      color: #fefbf2;
      line-height: 1.04rem;
      text-align: center;
    }
    .active {
      width: 50%;
      height: 1.04rem;
      background: #ffffff;
      border-radius: 0.2rem 0.2rem 0 0;
      color: #f9645e;
    }
  }
  .list-content {
    background: #ffffff;
    ul {
      padding: 0.32rem;
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.3rem;
        .con-img {
          width: 0.84rem;
          height: 0.84rem;
          border-radius: 0.84rem;
          background: #6d3634;
        }
        .prize-title {
          width: 2.4rem;
          font-size: 0.3rem;
          font-family: Arial;
          font-weight: 400;
          color: #666666;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden; //溢出内容隐藏
          text-overflow: ellipsis; //文本溢出部分用省略号表示
          display: -webkit-box; //特别显示模式
          -webkit-line-clamp: 2; //行数
          line-clamp: 2;
          -webkit-box-orient: vertical; //盒子中内容竖直排列
        }
      }
    }
  }
  .conLeft {
    border-radius: 0rem 0.18rem 0.18rem 0.18rem;
  }
  .conRight {
    border-radius: 0.18rem 0rem 0.18rem 0.18rem;
  }
}
.empty {
  justify-content: center !important;
}
</style>
