<template>
  <div class="get-prize" v-if="prizeList.length > 0">
    <div class="prize-num">
      <div
        :class="['prize-title', i == index ? 'active' : '']"
        v-for="(item, index) in prizeList"
        :key="item.id"
        @click="switchPrize(index)"
      >
        奖品{{ index + 1 }}
      </div>
    </div>
    <div class="prize-con">
      <div class="con-01">
        <div class="prize-img">
          <img
            class="w-100 h-100"
            :src="`${baseURL}/imgs/market_fission_prize_img/${prizeList[i].goodsImgId}`"
            alt=""
          />
        </div>
        <div class="prize-title">
          <div class="name">奖品：{{ prizeList[i].name }}</div>
          <div class="repertory">剩余{{ prizeList[i].stocks }}</div>
        </div>
      </div>
    </div>
    <div class="prize-pro">
      <div class="pro-title">
        <div><img src="@/assets/images/bao001.png" alt="" srcset="" /></div>
        <div>
          <span class="size-24">已成功邀请{{ count }}人</span>
          <span v-if="count >= prizeList[i].nums">,任务完成</span>
        </div>
        <div><img src="@/assets/images/bao001.png" alt="" srcset="" /></div>
      </div>
      <div
        class="pro-box"
        :style="{
          'background-color':
            count >= prizeList[i].nums ? '#ba2929' : '#cccccc',
        }"
      >
        <div class="pro-box-dot">
          <div class="dot dot-active"></div>
          <div :class="['dot', count > 0 ? 'dot-active' : '']"></div>
          <div
            :class="['dot', count == prizeList[i].nums ? 'dot-active' : '']"
          ></div>
        </div>
      </div>
      <div class="pro-bot">
        <div class="size-24">邀请好友</div>
        <div class="size-24">{{ prizeList[i].nums }}人</div>
      </div>
    </div>
    <div class="but-box">
      <van-button
        class="but-con"
        color="linear-gradient(181deg, #FD462A, #FB8F84)"
        v-if="count >= prizeList[i].nums"
        @click="accept"
        >去领奖品</van-button
      >
      <van-button
        v-else
        class="but-con"
        color="linear-gradient(181deg, #FD462A, #FB8F84)"
        @click="share"
        >还差{{ prizeList[i].nums - count }}人,立即邀请</van-button
      >
    </div>
    <div class="suss-box">已有{{ awardsCount }}人成功领取</div>
  </div>
</template>
<script>
export default {
  props: {
    prizeList: {
      type: Array,
      default: () => [],
    },
    count: {
      type: Number,
      default: 0,
    },
    awardsCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {},
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_API,
      i: 0,
    };
  },
  mounted() {},
  methods: {
    // 去领奖
    accept() {
      let eid = this.$route.query.eid;
      let fid = this.$route.query.fid;
      let prizeId = this.prizeList[this.i].id;
      this.$route.push(
        `/market/fission/info?eid=${eid}&fid=${fid}&prizeId=${prizeId}`
      );
    },
    // 分享
    share() {
      this.$emit("share");
    },
    switchPrize(index) {
      this.i = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.h-100 {
  height: 100%;
}
.get-prize {
  background: #ffffff;
  border-radius: 0.2rem;
  .prize-num {
    height: 1.14rem;
    border-bottom: 0.02rem solid #f4f4f4;
    flex-grow: 1;
    padding-left: 0.28rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // 设置超过不换行，隐藏
    overflow-x: auto;
    white-space: nowrap;

    .prize-title {
      display: inline;
      padding: 0.08rem 0.5rem;
      font-size: 0.32rem;
      font-family: PingFang SC;
      background: #e3e3e3;
      color: #999999;
      border-radius: 0.4rem;
      line-height: 0.6rem;
      text-align: center;
      margin-right: 0.08rem;
    }

    .active {
      font-weight: bold;
      color: #ffffff;
      background: linear-gradient(180deg, #ff934c, #fc686f);
    }
  }
  // 隐藏进度条
  ::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
  }
  .prize-con {
    padding: 0.4rem;
    .con-01 {
      display: flex;
      justify-content: flex-start;
      .prize-img {
        width: 1.48rem;
        height: 1.48rem;
        background: #333333;
      }
      .prize-title {
        width: 4rem;
        margin-left: 0.24rem;
        .name {
          font-size: 0.32rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #333333;
        }
        .repertory {
          border: 0.02rem solid #bfbfbf;
          border-radius: 0.06rem;
          font-size: 0.24rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #bfbfbf;
          display: inline-block;
          padding: 0.06rem 0.14rem;
          margin-top: 0.16rem;
        }
      }
    }
  }
  .prize-pro {
    padding: 0.4rem;
    .pro-title {
      display: flex;
      justify-content: space-between;
      line-height: 0.66rem;
      padding: 0rem 0.2rem;
      margin-bottom: 0.28rem;
      img {
        width: 0.66rem;
        height: 0.66rem;
      }
    }

    .pro-box {
      width: 100%;
      height: 0.14rem;
      background-color: #cccccc;
      border-radius: 0.08rem;
      .pro-box-dot {
        display: flex;
        justify-content: space-between;
        position: relative;
        padding: 0rem 0.2rem;
        top: -0.15rem;
        .dot {
          width: 0.16rem;
          height: 0.16rem;
          background: #ffffff;
          border: 0.14rem solid #999999;
          box-shadow: 0 0.04rem 0.08rem 0 rgba(137, 137, 137, 0.26);
          border-radius: 50%;
        }
        .dot-active {
          border: 0.14rem solid #f84b48;
        }
      }
    }
    .pro-bot {
      display: flex;
      justify-content: space-between;
      padding: 0rem 0.2rem;
      margin-top: 0.4rem;
    }
  }
  .but-box {
    padding: 0 0.8rem;
    .but-con {
      width: 100%;
      height: 0.88rem;
      box-shadow: 0 0.04rem 0.02rem 0 rgba(255, 255, 255, 0.6);
      border-radius: 0.44rem;
      font-size: 0.34rem;
      font-family: Source Han Sans CN;
      font-weight: 800;
      color: #ffffff;
      line-height: 0.6rem;
    }
  }
  .suss-box {
    font-size: 0.24rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #f84b48;
    line-height: 0.44rem;
    text-align: center;
    padding: 0.26rem 0 0.4rem 0;
  }
}
</style>
