<template>
  <div class="login"></div>
</template>

<script>
import { loginUrl } from '@/api/user';
export default {
  name: 'loginBeforeView',
  components: {},
  data() {
    return {};
  },
  mounted() {
    let eid = window.sessionStorage.getItem('eid');
    // loginUrl({ eid: eid }).then((res) => {
    loginUrl().then((res) => {
      if (res.errcode == 0) {
        window.location.href = res.datas.url;
      }
    });
  },
  methods: {},
};
</script>
