
import Vue from "vue";
import VueRouter from "vue-router";
import loginView from "../views/login.vue";
import loginBeforeView from "../views/login_before.vue";
import homeView from "../views/home";
import errorView from "../views/error";
import xxpyRouter from './modules/xyj_lz_xhx';
import toolsRouter from './tools';

Vue.use(VueRouter);

const routes = [
  ...xxpyRouter,
  {
    path: '/',
    name: 'home',
    component: homeView,
    meta: {
      title: '请稍后',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: loginView,
    meta: {
      title: '请稍后',
    },
  },
  {
    path: '/login_before',
    name: 'login_before',
    component: loginBeforeView,
    meta: {
      title: '请稍后',
    },
  },
  {
    path: '/error',
    name: 'error',
    component: errorView,
    meta: {
      title: '错误页面',
    },
  },
  {
    path: '/market/fission/index',
    name: '/market/fission/index',
    component: () => import('../views/market/fission/index.vue'),
    meta: {
      title: '邀请好友得福利',
    },
  },
  {
    path: '/market/fission/help',
    name: '/market/fission/help',
    component: () => import('../views/market/fission/help.vue'),
    meta: {
      title: '邀请好友得福利',
    },
  },
  {
    path: '/market/fission/info',
    name: '/market/fission/info',
    component: () => import('../views/market/fission/info.vue'),
    meta: {
      title: '填写领奖信息',
    },
  },
  // 链接雷达页面
  {
    path: '/operate/links',
    name: '/operate/links',
    component: () => import('../views/operate/links.vue'),
    meta: {
      title: '请稍后',
    },
  },
  // 场所活码
  {
    path: '/guide/place_qr/index',
    name: '/guide/place_qr/index',
    component: () => import('../views/guide/place_qr/index'),
    meta: {
      title: '二维码名称',
    },
  },
  ...toolsRouter,
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
