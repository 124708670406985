import request from "@/utils/request";
import { routerBase,routerUrl } from '@/settings'
// 企微code登录
export function loginCode(data) {
  return request({
    url: "/basic/login_code",
    method: "post",
    data, //如果是application/x-www-form-urlencoded这种提交方式，可直接用qs.stringify
  });
}

// 获取授权登录地址
export function loginUrl(data) {
  return request({
    url: "/basic/login_url",
    method: "post",
    data, //如果是application/x-www-form-urlencoded这种提交方式，可直接用qs.stringify
  });
}


// 获取详情
export function userDetail(type, data) {
  return request(
    {
      url: `${routerBase}/${type}/user_detail`,
      method: 'post',
      data,
    },
    {},
    
  );
}
// 获取验证码
export function sendCode(type, data) {
  return request({
    url: `${routerBase}/${type}/send_code`,
    method: 'post',
    data,
  });
}
// 绑定
export function bindPhone(type, data) {
  return request({
    url: `${routerBase}/${type}/bind_phone`,
    method: 'post',
    data,
  });
}
// 访问群二维码，目前只是用来记录用户访问入群二维码的时间
export function groupQrcode(type, data) {
  return request({
    url: `${routerBase}/${type}/group_qrcode`,
    method: 'post',
    data,
  });
}

// 兰芝雪花秀-开始购买
export function startBuy(type, data){
  return request({
    url: `${routerBase}/${type}/start_buy`,
    method: 'post',
    data,
  });
}