<template>
  <div class="login"></div>
</template>

<script>
import { loginCode } from '@/api/user';
export default {
  name: 'loginView',
  components: {},
  data() {
    return {
      eid: '',
      code: '',
      token: '',
      mes: '',
    };
  },
  mounted() {
    if (this.$route.query.code) {
      this.code = this.$route.query.code;
      this.getLoginCode();
    }
    this.eid = window.sessionStorage.getItem('eid');
  },
  methods: {
    getLoginCode() {
      let eid = window.sessionStorage.getItem('eid');
      // loginCode({ eid: eid, code: this.code }).then((res) => {
      loginCode({ code: this.code }).then((res) => {
        // 如果返回了url，证明还没有登录成功，重新走一遍流程
        if (res.errcode == 0) {
          if (res.datas && res.datas.url) {
            window.location.href = res.datas.url;
          } else {
            window.location.href = sessionStorage.getItem('redirectPage');
          }
        }
      });
    },
  },
};
</script>
