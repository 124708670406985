<template>
  <div class="about">
    <get-prize></get-prize>
    <active-list></active-list>
    <not-part></not-part>
  </div>
</template>
<script>
import activeList from '@/components/market/activeList.vue';
import GetPrize from '@/components/market/getPrize.vue';
import NotPart from '@/components/market/notPart.vue';
export default {
  components: { activeList, GetPrize, NotPart },
  mounted() {},
  methods: {},
};
</script>
