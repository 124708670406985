export default [
  {
    path: '/tools/wxlogin/index',
    name: 'tools/index',
    component: () => import('@/views/tools/wxlogin/index'),
    meta: {
      title: '请稍后',
    },
  },
  {
    path: '/tools/wxlogin/login',
    name: 'tools/login',
    component: () => import('@/views/tools/wxlogin/login'),
    meta: {
      title: '请稍后',
    },
  },
];
