import { routerBase,routerUrl } from '@/settings'

export default [
  {
    path: `${routerBase}/${routerUrl}/rule`,
    name: `${routerUrl}/rule`,
    component: () => import(`@/views/${routerUrl}/rule`),
    meta: {
      title: '活动规则',
    },
  },
  {
    path: `${routerBase}/${routerUrl}/result`,
    name: `${routerUrl}/result`,
    component: () => import(`@/views/${routerUrl}/result`),
    meta: {
      title: '小样领取成功',
    },
  },
  {
    path: `${routerBase}/${routerUrl}/register`,
    name: `${routerUrl}/register`,
    component: () => import(`@/views/${routerUrl}/register`),
    meta: {
      title: '领取小样',
    },
  },
  {
    path: `${routerBase}/${routerUrl}/privacyPolicy`,
    name: `${routerUrl}/privacyPolicy`,
    component: () => import(`@/views/${routerUrl}/privacyPolicy`),
    meta: {
      title: '隐私条款',
    },
  },
  {
    path: `${routerBase}/${routerUrl}/authorization`,
    name: `${routerUrl}/authorization`,
    component: () => import(`@/views/${routerUrl}/authorization`),
    meta: {
      title: '个人信息授权提示',
    },
  },
];
